import "./App.css"

function App() {
    return (
        <div className="main-container">
            <div className="main-grid">
                <div className='a'>alexander peasey</div>
                <div>website coming soon</div>
                <div><a href="mailto:alexander.peasey@gmail.com">📮 email me</a></div>
            </div>
        </div>
    )
}

export default App
